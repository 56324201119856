<!--
#########################################################################################
######################           Vista SVC40000  :  RECUENTOS           #################
#########################################################################################
-->
<template>
  <div style="height:100%">
      <!-- Top Navigation (Genérico) -->
      <TNC10000></TNC10000>

      <!-- Rangos -->
      <div class="row igs-back-color8">
              <div class="col-md-2 igs-back-color8 igs-center">
                &nbsp;&nbsp;
                <date-range-picker
                              ref="dp_RangoFechasDetalleMovimientos"
                              opens="right"
                              :append-to-body=true
                              :single-date-picker=false
                              :locale-data="dp1_localeData"
                              minDate="2023-01-01" maxDate="2035-12-31"
                              :timePicker=false
                              :timePicker24Hour=true
                              :showWeekNumbers=true
                              :showDropdowns=true
                              :autoApply=true
                              :ranges="dp1_defaultRanges"
                              :linkedCalendars=false
                              control-container-class="igs-btn-dh-fecha"
                              v-model="dp1_dateRange"
                              @update="lp30RangoFechasDetalleMovimientos"
                              >
                            <template v-slot:input="picker">
                              <div class="igs-color1 igs-l4 igs-mt-5 igs-center">
                                <b>{{ picker.startDate | lf60FiltroFecha }} <br/>a<br/> {{ picker.endDate | lf60FiltroFecha }}</b>
                              </div>
                            </template>
                </date-range-picker>
              </div>
              <div class="col-md-3 igs-center">
                <div class="igs-center igs-back-color2 igs-color0">Artículos</div>
                <div class="row igs-mt-5">
                  <div class="col-md-3 igs-l3 igs-mt-5 igs-left">&nbsp;&nbsp;<input class="igs-radio-back-color7" type="radio" value="0" v-model="ltFiltroDiferencias" @change="lp60CambioTareaRecuento()"/>&nbsp;Todos</div>
                  <div class="col-md-5 igs-l3 igs-mt-5 igs-left"><input class="igs-radio-back-color7" type="radio" value="1" v-model="ltFiltroDiferencias" @change="lp60CambioTareaRecuento()"/>&nbsp;Con Diferencias</div>
                  <div class="col-md-4 igs-l3 igs-mt-5 igs-left"><input class="igs-radio-back-color7" type="radio" value="2" v-model="ltFiltroDiferencias" @change="lp60CambioTareaRecuento()"/>&nbsp;Sin Diferencias</div>                        
                </div>
              </div>
              <div class="col-md-3 igs-center">
                <div class="igs-center igs-back-color4 igs-color0">Ubicaciones</div>
                <div class="row igs-mt-5">
                  <div class="col-md-3 igs-l3 igs-mt-5 igs-left">&nbsp;&nbsp;<input class="igs-radio-back-color7" type="radio" value="0" v-model="ltFiltroRecuento" @change="lp60CambioTareaRecuento()"/>&nbsp;Todas</div>
                  <div class="col-md-5 igs-l3 igs-mt-5 igs-left"><input class="igs-radio-back-color7" type="radio" value="1" v-model="ltFiltroRecuento" @change="lp60CambioTareaRecuento()"/>&nbsp;Recontadas</div>
                  <div class="col-md-4 igs-l3 igs-mt-5 igs-left"><input class="igs-radio-back-color7" type="radio" value="2" v-model="ltFiltroRecuento" @change="lp60CambioTareaRecuento()"/>&nbsp;Sin Recontar</div>                        
                </div>
                <div class="row igs-mt-5">
                  <div class="col-md-3 igs-l3 igs-mt-5 igs-left">&nbsp;&nbsp;<input class="igs-radio-back-color7" type="radio" value="0" v-model="ltFiltroOcupacion" @change="lp60CambioTareaRecuento()"/>&nbsp;Todas</div>
                  <div class="col-md-5 igs-l3 igs-mt-5 igs-left"><input class="igs-radio-back-color7" type="radio" value="1" v-model="ltFiltroOcupacion" @change="lp60CambioTareaRecuento()"/>&nbsp;Ocupadas</div>
                  <div class="col-md-4 igs-l3 igs-mt-5 igs-left"><input class="igs-radio-back-color7" type="radio" value="2" v-model="ltFiltroOcupacion" @change="lp60CambioTareaRecuento()"/>&nbsp;Libres</div>                        
                </div>
              </div>
              <div class="col-md-4">
                <div class="igs-center igs-back-color7 igs-color0">Información Adicional</div>
              </div>
      </div>

      <div style="height:80vh">

        <div class="row">
          <!--------------------------------->
          <!-- Lista de Tareas de Recuento -->
          <!--------------------------------->
          <div class="col-md-2 igs-l-pad-20 igs-left">
                <div class="igs-back-color7 igs-color0 igs-center">Lista de Tareas</div>
                <div>
                  <select v-model="ltTareaRecuentoSeleccionada" @change="lp60CambioTareaRecuento()" class="igs-fs-14 " size="24" style="border:0px;outline:0px;height:80vh;">
                      <option v-for="item in laTareasRecuentoDisponibles" :key="item.tcl0" :value="item.tcl0"><span v-html="lf95EstadoTarea(item.t991)"></span> {{ f70AMD2fDMA(item.t036) }} {{ item.t012 }} &nbsp;&nbsp;&nbsp;&nbsp;</option>
                  </select>              
                </div>
          </div>

          <div class="col-md-10">

            <!-- HUD -->
            <div class="row">
              <div class="col-12 igs-ancho-100 igs-center" v-if="lbCargando">  
                <br/><br/>
                <h2 class="igs-color2">Buscando resultados ....</h2>
                <br/><br/>
                <vue-ellipse-progress :loading="true" />
              </div>
            </div>

            <!-- Resultados -->
            <div class="row igs-back-color8"  v-if="!lbCargando">
                  <div class="col-md-12" v-if="ltFechaRecuentoTareaActual != '00000000'"><hr/></div>
                  <div class="col-md-3 igs-center" v-if="ltFechaRecuentoTareaActual != '00000000'">
                    <span class="igs-color7">Tarea  </span>
                    <span class="igs-bold">{{ f70AMD2fDMA(ltFechaRecuentoTareaActual) }} - </span>
                    <span class="igs-bold">{{ ltDescripcionTareaActual }}</span>
                  </div>
                  <div class="col-md-9 igs-l3 igs-mt-5" v-if="ltFechaRecuentoTareaActual != '00000000'">
                    <div class="row">
                      <div class="col-md-3 igs-center">
                        <span class="igs-color7">Creada&nbsp;&nbsp;</span>
                        <span class="igs-bold">{{ f70AMD2fDMA(ltFechaCreacionTareaActual) }} {{ f70HMS2fHMS(ltHoraCreacionTareaActual) }}<br/>({{ltUsuarioCreacionTareaActual}})</span>
                      </div>
                      <div class="col-md-3 igs-center">
                        <span class="igs-color7">Cerrada&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span class="igs-bold">{{ f70AMD2fDMA(ltFechaCierreTareaActual) }} {{ f70HMS2fHMS(ltHoraCierreTareaActual) }}<br/>({{ltUsuarioCierreTareaActual}})</span>
                      </div>
                      <div class="col-md-6 igs-center igs-pad-0">
                        <nobr>
                          <span>Artículos&nbsp;<b>{{f91Porcentaje(lf91ArticulosMostrados/lf91ArticulosRecibidos*100)}}%</b><span class="igs-l2">&nbsp;({{lf91ArticulosMostrados}}/{{ lf91ArticulosRecibidos }})</span></span>&nbsp;&nbsp;&nbsp;
                          <span>Huecos&nbsp;<b>{{f91Porcentaje(lf91UbicacionesMostradas/lf91UbicacionesRecibidas*100)}}%</b><span class="igs-l2">&nbsp;({{lf91UbicacionesMostradas}}/{{lf91UbicacionesRecibidas}})</span></span>&nbsp;&nbsp;&nbsp;
                          <span>Cantidades&nbsp;<b>{{f91Porcentaje(lf91SumaDiferencias/lf91SumaCantidades*100)}}%</b><span class="igs-l2">&nbsp;({{f91Cantidad(lf91SumaDiferencias)}}/{{f91Cantidad(lf91SumaCantidades)}})</span></span>
                        </nobr>
                      </div>
                    </div>

                  </div>
            </div>

            <div style="height:73vh;overflow-x:auto; overflow-y:scroll;">
              <table ref="Tabla400" v-if="!lbCargando" class="igs-ancho-100">
                  <thead style="position:sticky;top:0;">
                    <tr class="igs-l2 igs-back-color2 igs-color0 igs-pad-0">
                      <th scope="col" colspan="4">&nbsp;</th>
                      <th scope="col" colspan="2" class="igs-back-color1">Mapa</th>
                      <th scope="col" colspan="2" class="igs-back-color7">Recuento</th>
                      <th scope="col" colspan="2" class="igs-back-color3">Diferencia</th>
                      <th scope="col">Otros</th>
                    </tr>
                    <tr class="igs-l2 igs-back-color2 igs-color0">
                      <th scope="col" @click="lp50Sort(0)">Ubicación<span  v-html="lf91ColumnaOrden(0)" /></th>
                      <th scope="col" @click="lp50Sort(1)" >Artículo<span  v-html="lf91ColumnaOrden(1)" /></th>
                      <th scope="col" @click="lp50Sort(2)">Lote<span  v-html="lf91ColumnaOrden(2)" /></th>
                      <th scope="col" @click="lp50Sort(3)">Caducidad<span  v-html="lf91ColumnaOrden(3)" /></th>
                      <th scope="col" @click="lp50Sort(4)" class="igs-back-color1">Cantidad<span  v-html="lf91ColumnaOrden(4)" /></th>
                      <th scope="col" @click="lp50Sort(5)" class="igs-back-color1">Unidades<span  v-html="lf91ColumnaOrden(5)" /></th>
                      <th scope="col" @click="lp50Sort(6)" class="igs-back-color7">Cantidad<span  v-html="lf91ColumnaOrden(6)" /></th>
                      <th scope="col" @click="lp50Sort(7)" class="igs-back-color7">Unidades<span  v-html="lf91ColumnaOrden(7)" /></th>
                      <th scope="col" @click="lp50Sort(8)" class="igs-back-color3">Cantidad<span  v-html="lf91ColumnaOrden(8)" /></th>
                      <th scope="col" @click="lp50Sort(9)" class="igs-back-color3">Unidades<span  v-html="lf91ColumnaOrden(9)" /></th>
                      <th scope="col" @click="lp50Sort(10)">Datos<span  v-html="lf91ColumnaOrden(10)" /></th>
                    </tr>
                  </thead>
                  <tbody>
                      <!-- Lista de Resultados -->
                      <tr v-for="(tra, index) in lf50ListaRegistrosTRA" :key="index" class="igs-l3">
                        <td class="igs-l2"><span  v-html="f93MaquetarUbicacion(tra.t081,5)"></span></td>
                        <td class="igs-left">{{ tra.t002 }} - {{ tra.a002 }}</td>
                        <td>{{ tra.t003 }}</td>
                        <td>{{ lf91FechaCaducidad(tra.t031) }}</td>
                        <td>{{ f91Cantidad(tra.t511) }}</td>
                        <td>{{ tra.t512 }}</td>
                        <td>{{ f91Cantidad(tra.t521) }}</td>
                        <td>{{ tra.t522 }}</td>
                        <td :class="lf91ClaseDiferencia(tra.t531,tra.t532)">{{ f91Cantidad(tra.t531) }}</td>
                        <td :class="lf91ClaseDiferencia(tra.t531,tra.t532)">{{ tra.t532 }}</td>
                        <td class="igs-l2">
                          &nbsp;
                        </td>
                      </tr>
                  </tbody>
              </table>          
            </div>
          </div>
        </div>

      </div>


      <BNC10000></BNC10000>
  </div>
</template>

<script>
import igs_mixin from '@/igs-mixins/igs_mixin'
import TNC10000  from '@/igs-navegacion/TNC10000'
import BNC10000  from '@/igs-navegacion/BNC10000'
import axios     from 'axios';

//-- Rango de Fechas
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  mixins:       [ igs_mixin ],
  components :  { TNC10000, BNC10000, DateRangePicker },

  //----------------------------
  //-- Datos Locales
  //----------------------------
  data() {
    //-- Valores Predeterminado
    let startDate = new Date();
    let endDate   = new Date();
    startDate.setDate(endDate.getDate() - 30);

    let today = new Date()
    today.setHours(0, 0, 0, 0)

    let yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0);

    var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);

    return {
       //------------------------------------------------------------------------
       //-- Variables Locales
       //------------------------------------------------------------------------

       //-- Lista de Tareas de Recuento disponibles
       laTareasRecuentoDisponibles : [],          

       //-- Registros Individuales de la Tarea de Recuento Activa (TRA)
       laRegistrosTRA : [],

       ltTareaRecuentoSeleccionada : '',          //-- Tarea de Recuento Actual

       ltFechaCreacionTareaActual : '00000000',
       ltHoraCreacionTareaActual : '000000',
       ltUsuarioCreacionTareaActual : '',

       ltFechaCierreTareaActual : '00000000',
       ltHoraCierreTareaActual : '000000',
       ltUsuarioCierreTareaActual : '',

       ltFiltroDesdeFecha   : '00000000',         //-- Desde Fecha (Inicio Tarea Recuento)
       ltFiltroHastaFecha   : '99999999',         //-- Hasta Fecha (Inicio Tarea Recuento)
       ltFiltroDiferencias  : '0',                //-- Filtro basado en Diferencias               (0-Todos, 1-Con Diferencias, 2-Sin Diferencias)   
       ltFiltroRecuento     : '0',                //-- Filtro basado en Ubicaciones               (0-Todas, 1-Recontadas, 2-Sin Recontar)   
       ltFiltroOcupacion    : '0',                //-- Filtro basado en Ocupación de la Ubicación (0-Todas, 1-Ocupadas, 2-Libres)   

       lnColumnaOrden : 0,                        //-- Índice de la Columna por la cual Ordenar los Resultados
       lbOrdenAscendente : true,                  //-- Ordenar los Resultados de forma ASCENDENTE (S/N)

       lbCargando : false,                        //-- Cargando Datos 

       dp1_dateRange: {startDate, endDate},
       dp1_localeData : {
          direction: 'ltr',
          format: 'dd-mm-yyyy',
          separator: ' a ',
          applyLabel: 'Confirmar',
          cancelLabel: 'Cancelar',
          weekLabel: 'W',
          customRangeLabel: 'Rango',
          daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          firstDay: 1
       },
       dp1_defaultRanges : {
          'Hoy': [today, today],
          'Ayer': [yesterday, yesterday],
          'Mes Actual': [thisMonthStart, thisMonthEnd],
          'Mes Anterior': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
          'Año Actual': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)]
       },

       ltDescripcionTareaActual : '',             //-- Descripción Tarea Seleccionada
       ltFechaRecuentoTareaActual : '00000000'    //-- Fecha de la Tarea de Recuento Seleccionada
        
    }
              

  },

  //----------------------------
  //-- Métodos Locales
  //----------------------------
  methods: {

      //............................................................................................
      //-- Conversión (Date) en (String) de la Fecha Inicial y Final para el Detalle de Movimientos
      //............................................................................................
      lp30RangoFechasDetalleMovimientos() {
          //-- Extraer Fecha INICIAL
          let startDate    = this.dp1_dateRange.startDate;
          let fechaInicial = this.f92Date2DTZ(startDate).substr(0,10).replace(/-/g,'');

          let endDate      = this.dp1_dateRange.endDate;
          let fechaFinal   = this.f92Date2DTZ(endDate).substr(0,10).replace(/-/g,'');

          //-- Retornar el valor calculado
          //console.log(' fechaInicial = ('+fechaInicial+') // fechaFinal = ('+fechaFinal+')');

          this.ltFiltroDesdeFecha   = fechaInicial;
          this.ltFiltroHastaFecha   = fechaFinal;

          //-- Recalcular la Lista de Tareas de Recuento disponibles
          this.lp98LeerTareasRecuentoDisponibles();
      },


      //----------------------------------------------------------------------
      //-- Ordenar Resultados (por el ÍNDICE de la COLUMNA indicada)
      //----------------------------------------------------------------------
      lp50Sort(indice) {
        //console.log(' lp50Sort ('+indice+')');

        //-- Misma Columna --> Conmutar Orden Ascendente/Descendente
        if (indice == this.lnColumnaOrden) {
          this.lbOrdenAscendente  = !this.lbOrdenAscendente;
        } else {
          //-- Actualizar Columna y Orden
          this.lnColumnaOrden     = indice;
          this.lbOrdenAscendente  = true;
        }

        //-- Ordenar Resultados
        this.lp60OrdenarResultados();

      },

      //-----------------------------------------------------------------
      //-- Al cambiar de Tarea de Recuento
      //-----------------------------------------------------------------
      lp60CambioTareaRecuento() {
        console.log('--> Cambio de Tarea de Recuento');

        let item = this.laTareasRecuentoDisponibles.filter((obj) => ((obj.tcl0 == this.ltTareaRecuentoSeleccionada)));

        if ((item != null) && (item[0])) {

          //-- Actualizar Rótulos
          this.ltDescripcionTareaActual       = item[0].t012;
          this.ltFechaRecuentoTareaActual     = item[0].t036;

        } else {

          //-- Inicializar Rótulos
          this.ltDescripcionTareaActual       = '';
          this.ltFechaRecuentoTareaActual     = '00000000';

        }

        //-- Lectura Detalle de la Tarea de Recuento Activa
        this.lp98LeerTareaRecuentoActiva();
        
      },

      //----------------------------------------------------------------------
      //-- Ordenar los MOVIMIENTOS por el criterio seleccionado
      //----------------------------------------------------------------------
      lp60OrdenarResultados() {

        //-- Crear nueva lista con el Orden seleccionado
        let self = this;
        let listaOrdenada = this.laRegistrosTRA.sort(function(a, b){
          var nameA = '';
          var nameB = '';

          //-- Ubicación + Artículo
          if (self.lnColumnaOrden == 0) {
              nameA=a.t081+a.t002;
              nameB=b.t081+b.t002;

              if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
              if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

              if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
              if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

              return 0; 
          }

          //-- Artículo
          if (self.lnColumnaOrden == 1) {
              nameA=a.t002+a.t081;
              nameB=b.t002+b.t081;

              if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
              if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

              if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
              if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

              return 0; 
          }

          //-- Lote
          if (self.lnColumnaOrden == 2) {
              nameA=a.t003+a.t002;
              nameB=b.t003+b.t002;

              if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
              if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

              if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
              if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

              return 0; 
          }

          //-- Caducidad
          if (self.lnColumnaOrden == 3) {
              nameA=a.t031+a.t002;
              nameB=b.t031+b.t002;

              if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
              if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

              if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
              if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

              return 0; 
          }

          //-- Cantidad Teórica
          if (self.lnColumnaOrden == 4) {
              nameA=a.t511;
              nameB=b.t511;

              if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
              if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

              if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
              if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

              return 0; 
          }

          //-- Unidades Teóricas
          if (self.lnColumnaOrden == 5) {
              nameA=a.t512;
              nameB=b.t512;

              if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
              if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

              if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
              if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

              return 0; 
          }

          //-- Cantidad Real
          if (self.lnColumnaOrden == 6) {
              nameA=a.t521;
              nameB=b.t521;

              if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
              if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

              if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
              if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

              return 0; 
          }

          //-- Unidades Reales
          if (self.lnColumnaOrden == 7) {
              nameA=a.t522;
              nameB=b.t522;

              if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
              if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

              if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
              if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

              return 0; 
          }

          //-- Diferencia Cantidades
          if (self.lnColumnaOrden == 8) {
              nameA=Math.abs(a.t531);
              nameB=Math.abs(b.t531);

              if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
              if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

              if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
              if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

              return 0; 
          }

          //-- Diferencia Unidades
          if (self.lnColumnaOrden == 9) {
              nameA=Math.abs(a.t532);
              nameB=Math.abs(b.t532);

              if ((nameA < nameB) && self.lbOrdenAscendente)  { return -1; }
              if ((nameA > nameB) && self.lbOrdenAscendente)  { return  1; }

              if ((nameA < nameB) && !self.lbOrdenAscendente) { return  1; }
              if ((nameA > nameB) && !self.lbOrdenAscendente) { return -1; }

              return 0; 
          }

          //-- Resto de Casos
          return 0;
        });

        //-- Reemplazar Lista de Resultados                                              
        this.laRegistrosTRA = listaOrdenada;

      },


      //................................................................
      //-- Clase para aplicar en las DIFERENCIAS
      //................................................................
      lf91ClaseDiferencia(cantidad,unidades) {

        //-- Sin Diferencias : Nada que destacar
        if ((cantidad == 0) && (unidades == 0)) { return ''; } 

        //-- Con Diferencias : Resaltar
        let clase = 'igs-bold igs-l4';

        if ((cantidad > 0) || (unidades > 0)) {
          clase += ' igs-color9';
        } else {
          clase += ' igs-color8';
        }

        //-- Retornar Clase COMPLETA
        return clase;
      },

      //................................................................
      //-- Caracter para representar el ESTADO de la TAREA DE RECUENTO
      //................................................................
      lf95EstadoTarea(estado) {

        let caracter = '&#x2753;&nbsp;';       //-- En curso (Recontando)

        if (estado == 1)  { caracter = '&#x2705;&nbsp;' }  //--  OK   (Completada)
        if (estado == 5)  { caracter = '&#x279C;&nbsp;' }  //-- (->)  (En Curso)
        if (estado == 6)  { caracter = '&#x2795;&nbsp;' }  //-- (?)   (Disponible - Sin Empezar)
        if (estado == 9)  { caracter = '&#x274C;&nbsp;' }  //-- (X)   (Bloqueada  - Sin Empezar)
                
        //-- Retornar el Estado
        return caracter;
      },

      //----------------------------------------------------------------------
      //-- Indicador de Columna y Orden aplicado
      //----------------------------------------------------------------------
      lf91ColumnaOrden(indice) {

        //-- Inicializar Resultado
        let textoHTML = '';

        //-- Aplicar Texto HTML ÚNICAMENTE sobre la columna por la cual está siendo ordenado el resultado
        if (indice == this.lnColumnaOrden) {
          if (this.lbOrdenAscendente) {
            textoHTML = '&nbsp;&nbsp;<i class="fa-solid fa-sort-up"/>';
          } else {
            textoHTML = '&nbsp;&nbsp;<i class="fa-solid fa-sort-down"/>';
          }
        }

        //-- Retornar Resultado
        return textoHTML;

      },

      //----------------------------------------------------------------------
      //-- Maquetación de la FECHA DE CADUCIDAD
      //----------------------------------------------------------------------
      lf91FechaCaducidad(fecha) {

        //-- Valor "vacío" (cuando NO se recibe valor alguno)
        if ((fecha == '') || (fecha == '00000000')) {
          return '';
        }

        //-- Fecha Maquetada
        return this.f70AMD2fDMA(fecha);

      },

      //----------------------------------------------------------------------
      //-- Lectura de la Relación de TAREAS DE RECUENTO disponibles
      //----------------------------------------------------------------------
      lp98LeerTareasRecuentoDisponibles() {
        this.p99Log(5,1,' (lp98LeerTareasRecuentoDisponibles)');

        //-- Llamada al Servidor
        let parametros = this.ltFiltroDesdeFecha + '^' + this.ltFiltroHastaFecha;
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=411&x="+encodeURI(parametros);

        this.p99Log(1,1,' (lp98LeerTareasRecuentoDisponibles) URL = '+URL);

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200411) --');
            this.p99Log(5,2,datos);

            if (flg == global.lnkGetDataOk)  {

              //-- Reemplazar Lista de Fechas y Rutas con Carga Viva
              this.laTareasRecuentoDisponibles = datos.t200m411;

              //-- Mensaje de Éxito
              this.p99Log(5,1,' **** EXITO ****');

            } else {
              //-- Informar el Error
              this.p99Log(1,1,' #### ERROR (200411) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

      },

      //----------------------------------------------------------------------
      //-- Lectura de una TAREA DE RECUENTO ACTIVA (TRA)
      //----------------------------------------------------------------------
      lp98LeerTareaRecuentoActiva() {
        this.p99Log(5,1,' (lp98LeerTareaRecuentoActiva)');

        //-- Llamada al Servidor
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=421&x="+encodeURI(this.ltTareaRecuentoSeleccionada);

        this.p99Log(1,1,' (lp98LeerTareaRecuentoActiva) URL = '+URL);

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200421) --');
            this.p99Log(5,2,datos);

            if (flg == global.lnkGetDataOk)  {

              //-- Reemplazar Detalle de la Tarea Actual
              this.laRegistrosTRA = datos.t200m421;

              //-- Otros Atributos
              this.ltFechaCreacionTareaActual     = datos.b041;
              this.ltHoraCreacionTareaActual      = datos.b042;
              this.ltUsuarioCreacionTareaActual   = datos.b043;

              this.ltFechaCierreTareaActual       = datos.b071;
              this.ltHoraCiereTareaActual         = datos.b072;
              this.ltUsuarioCierreTareaActual     = datos.b073;

              //-- Ordenar Resultados
              this.lp60OrdenarResultados();

              //-- Mensaje de Éxito
              this.p99Log(5,1,' **** EXITO ****');

            } else {
              //-- Informar el Error
              this.p99Log(1,1,' #### ERROR (200421) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

      }      

  },

  /**************************************************************/
  /****************       Filtros / Formatos     ****************/
  /**************************************************************/
  filters: {
      //..............................................
      //--- Filtro para dar FORMATO a las FECHAS
      //..............................................
      lf60FiltroFecha: function (date) {

          let dw = date.toLocaleDateString('es-ES', { weekday: 'long' });
          dw = dw.charAt(0).toUpperCase() + dw.slice(1);

          //-- Fecha
          const d = date.getDate();
          const m = date.getMonth() + 1;
          //const y = date.getFullYear();
          const y = date.getFullYear();

          //-- Hora
          //const hora = date.getHours();
          //const minutos = date.getMinutes();

          //return dw + ' ' + (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y + ' ' + (hora < 10 ? '0' : '') + hora + ':' + (minutos < 10 ? '0' : '') + minutos;
          return dw + ' ' + (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y;
      }
  },

  //----------------------------
  //-- Datos Calculados
  //----------------------------
  computed: {

      //----------------------------------------------------------------------
      //-- Lista de Registros de Detalle a Mostrar (Filtrada)
      //----------------------------------------------------------------------
      lf50ListaRegistrosTRA() {

        let listaFiltrada = [];
        let i = 0;
        let reg   = null;
        let regOk = true;

        //-- Bucle de comprobación
        while (i<this.laRegistrosTRA.length) {

          //-- Puntero al registro a evaluar
          reg = this.laRegistrosTRA[i];
          regOk = true;

          //-- Comprobaciones
          //@#@#@#
          //::: Diferencias (S/N)
          if ((this.ltFiltroDiferencias == '1') &&  (reg.t531 == 0) && (reg.t532 == 0))   { regOk = false; }
          if ((this.ltFiltroDiferencias == '2') && ((reg.t531 != 0) || (reg.t532 != 0)))  { regOk = false; }

          //::: Recontados (S/N)
          if ((this.ltFiltroRecuento == '1') &&  (reg.t521 == 0) && (reg.t522 == 0))      { regOk = false; }
          if ((this.ltFiltroRecuento == '2') && ((reg.t521 != 0) || (reg.t522 != 0)))     { regOk = false; }

          //::: Ocupación (S/N)
          if ((this.ltFiltroOcupacion == '1') &&  (reg.t002 == ''))                       { regOk = false; }
          if ((this.ltFiltroOcupacion == '2') &&  (reg.t002 != ''))                       { regOk = false; }

          //-- Agregar (si cumple TODOS los filtros)
          if (regOk) { listaFiltrada.push(reg)}

          //-- Siguiente
          i += 1;
        }

        //-- Retornar Lista Filtrada
        return listaFiltrada;
      },

      //----------------------------------------------------------------------
      //-- Número de ARTÍCULOS ÚNICOS Mostrados
      //----------------------------------------------------------------------
      lf91ArticulosMostrados() {
        const a = [...new Set(this.lf50ListaRegistrosTRA.map(item => item.t002))]; 
        return a.length;
      },

      //----------------------------------------------------------------------
      //-- Número de ARTÍCULOS ÚNICOS Recibidos
      //----------------------------------------------------------------------
      lf91ArticulosRecibidos() {
        const a = [...new Set(this.laRegistrosTRA.map(item => item.t002))]; 
        return a.length;
      },

      //----------------------------------------------------------------------
      //-- Número de UBICACIONES ÚNICAS Mostrados
      //----------------------------------------------------------------------
      lf91UbicacionesMostradas() {
        const a = [...new Set(this.lf50ListaRegistrosTRA.map(item => item.t081))]; 
        return a.length;
      },

      //----------------------------------------------------------------------
      //-- Número de UBICACIONES ÚNICAS Recibidos
      //----------------------------------------------------------------------
      lf91UbicacionesRecibidas() {
        const a = [...new Set(this.laRegistrosTRA.map(item => item.t081))]; 
        return a.length;
      },

      //----------------------------------------------------------------------
      //-- Acumulado NETO de las Diferencias
      //----------------------------------------------------------------------
      lf91SumaDiferencias() {
        return this.laRegistrosTRA.reduce((n, {t531}) => n + t531, 0);
      },

      //----------------------------------------------------------------------
      //-- Acumulado NETO de las Cantidades
      //----------------------------------------------------------------------
      lf91SumaCantidades() {
        return this.laRegistrosTRA.reduce((n, {t511}) => n + t511, 0);
      }

  },

  //----------------------------
  //-- Al cargar esta página
  //----------------------------
  mounted() {
      //-- Actualizar Módulo "Vivo"
      this.$store.commit('p10StrModuleName', 'SVC40000');


      //-- Actualizar BreadCrumb 
      this.$store.commit('p10StrMenuBC', [
        {
          text: "4. Recuentos",
          href: "#"
        }
      ]);

     //-- Replicar Desde/Hasta Fecha sobre los campos (texto) equivalentes
     this.lp30RangoFechasDetalleMovimientos();

     //-- Cargar la Lista de Tareas de Recuento 
     //this.lp98LeerTareasRecuentoDisponibles();

  }

}
</script>

<style lang="scss">
.nav-pills > a.active {
  background-color: $bc1 !important;
}  
</style>
